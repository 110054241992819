import Card from "components/card";

const NftCard = (props: {
  image: string;
  title: string;
  extra?: string;
  id?: string;
  video?: string[];
}) => {
  const { title, image, extra } = props;
  const isVideoDefined = props.video && props.video.length > 0;

  return (
    <a href={`/admin/exercice/${props.id}`}>
      <Card
        extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
      >
        {!isVideoDefined && (
          <div className="absolute right-2 top-2 z-50">
            <div className="flex items-center justify-center rounded-full p-1">
              <svg
                className="h-6 w-6 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <p className="text-red-500">Aucune vidéo</p>
            </div>
          </div>
        )}
        <div className="h-full w-full">
          <div className="relative w-full">
            <img src={image} className="mb-3 h-full w-full rounded-md" alt="" />
          </div>

          <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
            <div className="mb-2">
              <p className="text-lg font-bold text-navy-700 dark:text-white">
                {" "}
                {title}{" "}
              </p>
            </div>
          </div>
        </div>
      </Card>
    </a>
  );
};

export default NftCard;
