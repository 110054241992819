// Admin Imports
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson
} from "react-icons/md";
import Workspaces from "views/admin/workspaces";
import UpdateWorkspace from "views/admin/workspaces/[id]";
import CreateWorkspaces from "views/admin/workspaces/create";
import ForgetPassword from "views/auth/ForgetPassword";

const routes = [
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Workspaces",
    layout: "/admin",
    path: "workspaces",
    icon: <MdHome className="h-6 w-6" />,
    component: <Workspaces />,
    show: true,
  },
  {
    name: "Ajouter un workspace",
    layout: "/admin",
    path: "workspaces/create",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateWorkspaces />,
  },
  {
    name: "Modifier un workspace",
    layout: "/admin",
    path: "workspaces/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <UpdateWorkspace />,
  },
  {
    name: "Mots de passe oublié?",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPassword />,
  },
];
export default routes;
