import { useSnack } from "common/hooks";
import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useGetWorkspace, useUpdateWorkspace } from "modules/workspaces/api";
import { CreateWorkspacePayload } from "modules/workspaces/types/workspaces.interface";
import { FormEvent, useCallback, useEffect } from "react";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";

const UpdateWorkspace = () => {
  const id = useParams<{ id: string | undefined }>().id;
  const { data } = useGetWorkspace(id);
  const { mutate } = useUpdateWorkspace(id);
  const setSnack = useSnack();
  const { formValues, setFormValues } = useCustomForm<CreateWorkspacePayload>({
    address: "",
    name: "",
    owners: [],
    siren: "",
    subscription: {
      subscriptionDuration: 0,
      subscriptionPrice: 0,
    },
  });
  const { formValues: owners, setFormValues: setOwners } = useCustomForm<{
    name: string;
    email: string;
  }>({
    name: "",
    email: "",
  });
  const subscription = formValues.subscription;
  const totalPrice = subscription.subscriptionPrice;

  const isSubmitDisabled =
    formValues.address === "" ||
    formValues.name === "" ||
    formValues?.owners?.length < 1 ||
    formValues.siren === "" ||
    formValues.subscription.subscriptionDuration === 0 ||
    formValues.subscription.subscriptionPrice === 0;

  useEffect(() => {
    if (data) {
      setFormValues({
        address: data.address,
        name: data.name,
        owners: data.owners,
        siren: data.siren,
        subscription: data.subscriptionPlan,
      });
    }
  }, [data, setFormValues]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate(formValues);
  };

  const handleRenderOwners = () => {
    if (!formValues.owners || formValues.owners.length < 1) return;

    return formValues.owners.map((owner, index) => (
      <div
        key={index}
        className="flex w-full items-center gap-5 xl:max-w-[800px]"
      >
        <div className="grid w-full grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom du gérant*"
            placeholder="Paul Dupont"
            id="nom-du-gerant"
            type="text"
            value={formValues.owners[index].name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                owners: [
                  ...formValues.owners.slice(0, index),
                  { ...formValues.owners[index], name: e.target.value },
                  ...formValues.owners.slice(index + 1),
                ],
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="paul.dupont@mail.com"
            id="email-du-gerant"
            type="text"
            value={formValues.owners[index].email}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                owners: [
                  ...formValues.owners.slice(0, index),
                  { ...formValues.owners[index], email: e.target.value },
                  ...formValues.owners.slice(index + 1),
                ],
              })
            }
          />
        </div>
        <div className="mt-4 flex items-center justify-start">
          <MdRemoveCircle
            className="cursor-pointer text-xl text-red-500"
            onClick={() =>
              setFormValues({
                ...formValues,
                owners: [
                  ...formValues.owners.slice(0, index),
                  ...formValues.owners.slice(index + 1),
                ],
              })
            }
          />
        </div>
      </div>
    ));
  };

  const handleAddOwner = useCallback(() => {
    if (!owners.name || !owners.email) {
      setSnack({
        title: "Veuillez remplir tous les champs",
        severityType: "error",
      });
      return;
    }

    const newData = {
      name: owners.name,
      email: owners.email,
    };

    setFormValues({
      ...formValues,
      owners: formValues.owners ? [...formValues.owners, newData] : [newData],
    });

    setOwners({
      name: "",
      email: "",
    });
  }, [
    formValues,
    owners.email,
    owners.name,
    setFormValues,
    setOwners,
    setSnack,
  ]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Informations du workspace">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom du workspace*"
            placeholder="Workspace Paul Dupont"
            id="name"
            type="text"
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Address*"
            placeholder="2 rue de la paix"
            id="address"
            type="text"
            value={formValues.address}
            onChange={(e) =>
              setFormValues({ ...formValues, address: e.target.value })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Siren*"
            placeholder="0000000"
            id="siren"
            type="text"
            value={formValues.siren}
            onChange={(e) =>
              setFormValues({ ...formValues, siren: e.target.value })
            }
          />
        </div>
      </CardWithTitle>
      <CardWithTitle title="Informations des gérants">
        {handleRenderOwners()}
        <div className="flex w-full items-center gap-5 xl:max-w-[800px]">
          <div className="grid w-full grid-cols-2 gap-5">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Nom du gérant*"
              placeholder="Paul Dupont"
              id="nom-du-gerant"
              type="text"
              value={owners.name}
              onChange={(e) =>
                setOwners({
                  ...owners,
                  name: e.target.value,
                })
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="paul.dupont@mail.com"
              id="email-du-gerant"
              type="text"
              value={owners.email}
              onChange={(e) =>
                setOwners({
                  ...owners,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="mt-4 flex items-center justify-start">
            <MdAddCircle
              className="cursor-pointer text-xl text-green-500"
              onClick={() => handleAddOwner()}
            />
          </div>
        </div>
      </CardWithTitle>
      <CardWithTitle title="Informations sur l'abonnement">
        <p className="-mt-2 mb-3 text-sm text-gray-400">
          (Les prix sont en euros)
        </p>

        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Duréer de l'abonnement*"
            placeholder="365"
            id="subscription-duration"
            type="number"
            value={subscription.subscriptionDuration}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                subscription: {
                  ...subscription,
                  subscriptionDuration: parseInt(e.target.value),
                },
              })
            }
            inputEnd={<span className="text-sm text-gray-400">jours</span>}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prix de l'abonnement*"
            placeholder="1000"
            id="subscription-price"
            type="number"
            value={subscription.subscriptionPrice}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                subscription: {
                  ...subscription,
                  subscriptionPrice: parseInt(e.target.value),
                },
              })
            }
            inputEnd={
              <span className="text-sm text-gray-400">
                €/{subscription.subscriptionDuration} jours
              </span>
            }
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          {subscription.subscriptionPrice > 0 && (
            <div>
              <p className="text-sm text-gray-400">Total </p>
              <p className="text-lg font-bold">{totalPrice} €</p>
            </div>
          )}
        </div>
        {totalPrice > 10000 && (
          <div className="mt-4">
            <p className="text-red-500">
              Stripe permet des paiements de moins de 10 000 €, nous devrons
              donc étaller le paiement en plusieurs mensualités.
            </p>
            <p className="mt-1.5 text-[#333]">
              Nombre de mensualités:{" "}
              <strong>{Math.ceil(totalPrice / 10000)}</strong>
            </p>
          </div>
        )}
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Modifier
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpdateWorkspace;
