import { useAppSelector } from "common/hooks";
import { FunctionComponent, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const LayoutController: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const auth = useAppSelector((state) => state.user.user) !== null;
  const location = useLocation();

  if (auth && location.pathname.startsWith("/auth")) {
    return <Navigate to="/" replace={true} />;
  }

  if (!auth && !location.pathname.startsWith("/auth")) {
    return <Navigate to="/auth/sign-in" replace={true} />;
  }

  return <>{children}</>;
};
