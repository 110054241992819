import { Button } from "components/button";
import InputField from "components/fields/InputField";
import { useSendResetPassword } from "modules/auth/api";
import { FormEvent, useState } from "react";

export default function ForgetPassword() {
  const { mutate } = useSendResetPassword();
  const [email, setEmail] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate(email);
  };

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Mots de passe oublié?
        </h4>
        <p className="mb-6 ml-1 text-base text-gray-600">
          Entrer votre email pour recevoir un lien de réinitialisation!
        </p>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@domain.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button onClick={handleSubmit}>Réinitialiser le mot de passe</Button>
      </div>
    </form>
  );
}
