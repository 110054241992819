import Card from "components/card";
import { useGetWorkspaces } from "modules/workspaces/api";
import { Workspace } from "modules/workspaces/types/workspaces.interface";
import ComplexTable, { RowObj } from "../default/components/ComplexTable";

const Workspaces = () => {
  const { data } = useGetWorkspaces();

  const handleFormatData = (data: Workspace[]): RowObj[] => {
    return data.map((item) => ({
      id: item._id,
      name: item.name,
      address: item.address,
      status:
        item.subscriptions.length > 0
          ? {
              type: "approved",
              text: "Abonnement actif",
            }
          : {
              type: "disable",
              text: "Pas d'abonnement actif",
            },
    }));
  };

  return (
    <div>
      <div className="mt-5">
        {data && data.length > 0 ? (
          <ComplexTable tableData={handleFormatData(data)} />
        ) : (
          <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between pt-4">
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Workspaces
              </div>
              <a
                className="cursor-pointer rounded-full bg-brand-500 p-0.5 text-white"
                href="/admin/workspaces/create"
              >
                <svg
                  className="h-8 w-8 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </a>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Workspaces;
