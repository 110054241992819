import { axiosInstance } from "common/api";
import { store } from "common/store";
import { Dispatch } from "redux";
import { setUserToken } from "./slice";

export const refreshTokenAction = () => async (dispatch: Dispatch) => {
  const refreshToken = store.getState().user.user?.token.refreshToken;

  await axiosInstance
    .post("/workspace/users/refresh-token", {
      refreshToken,
    })
    .then((response) => {
      if (response) {
        dispatch(setUserToken(response.data));
      }
    });
};
