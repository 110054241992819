import { environment } from "common/environment";
import {
  CreateWorkspacePayload,
  Workspace,
} from "modules/workspaces/types/workspaces.interface";

export interface IWorkspaceBackend {
  getWorkspaces(): Promise<Workspace[]>;
  getWorkspace(id: string): Promise<Workspace>;
  createWorkspace(payload: CreateWorkspacePayload): Promise<Workspace>;
  updateWorkspace(id: string, payload: CreateWorkspacePayload): Promise<Workspace>;
}

let workspaceBackendInstance: IWorkspaceBackend | undefined;

export async function getWorkspaceBackend(): Promise<IWorkspaceBackend> {
  if (workspaceBackendInstance === undefined) {
    const mod = await import(`./${environment.WORKSPACE_BACKEND}`);
    workspaceBackendInstance = new mod.default() as IWorkspaceBackend;
  }
  return workspaceBackendInstance;
}
