import { useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
    CreateWorkspacePayload,
    Workspace,
} from "modules/workspaces/types/workspaces.interface";
import { UseMutationResult, UseQueryResult, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getWorkspaceBackend } from "./backends";
import { WORKSPACE_ROUTES } from "./workspaces.api.enum";

export const useGetWorkspaces = (): UseQueryResult<Workspace[]> => {
  const setSnack = useSnack();

  const getWorkspaces = async (): Promise<Workspace[]> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getWorkspaces();
  };

  return useQueryWithLoading(WORKSPACE_ROUTES.GET_ALL, () => getWorkspaces(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useGetWorkspace = (
  id: string | undefined
): UseQueryResult<Workspace> => {
  const setSnack = useSnack();

  const getWorkspace = async (): Promise<Workspace> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.getWorkspace(id);
  };

  return useQueryWithLoading(WORKSPACE_ROUTES.GET + id, () => getWorkspace(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateWorkspace = (): UseMutationResult<
  Workspace,
  Error,
  CreateWorkspacePayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createWorkspace = async (
    payload: CreateWorkspacePayload
  ): Promise<Workspace> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.createWorkspace(payload);
  };

  return useMutationWithLoading(
    (payload: CreateWorkspacePayload) => createWorkspace(payload),
    {
      onSuccess() {
        setSnack({
          title: "Workspace créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(WORKSPACE_ROUTES.GET_ALL);

        navigate("/admin/workspaces");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateWorkspace = (
  id: string | undefined
): UseMutationResult<Workspace, Error, CreateWorkspacePayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateWorkspace = async (
    payload: CreateWorkspacePayload
  ): Promise<Workspace> => {
    const workspaceBackend = await getWorkspaceBackend();
    return await workspaceBackend.updateWorkspace(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateWorkspacePayload) => updateWorkspace(payload),
    {
      onSuccess() {
        setSnack({
          title: "Workspace modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(WORKSPACE_ROUTES.GET_ALL);

        navigate("/admin/workspaces");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};
