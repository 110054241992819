import { useProgress } from "common/hooks";
import { Spinner } from "components/Spinner";
import { FormEvent, FunctionComponent } from "react";

interface ButtonProps {
  onClick: (e?: FormEvent) => void;
  children: string;
  disabled?: boolean;
  variant?: "primary" | "alert";
}

export const Button: FunctionComponent<ButtonProps> = ({
  onClick,
  children,
  disabled,
  variant: style = "primary",
}) => {
  const { isLoading } = useProgress();
  const className =
    style === "primary"
      ? "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
      : "bg-red-500 hover:bg-red-600 active:bg-red-700";

  return (
    <button
      className={`linear mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200  disabled:bg-gray-300 disabled:text-gray-500  dark:text-white ${className}`}
      onClick={!disabled && onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Spinner size="sm" /> : children}
    </button>
  );
};
