import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import {
  AuthenticatedUser,
  ISignInWithUserPasswordPayload,
} from "modules/auth/types/auth.interface";

export interface IAuthBackend {
  signInWithEmailPassword: ({
    email,
    password,
  }: ISignInWithUserPasswordPayload) => Promise<AuthenticatedUser>;
  sendResetPassword: (email: string) => Promise<SuccessResponse>;
}

let authBackendInstance: IAuthBackend | undefined;

export async function getAuthBackend(): Promise<IAuthBackend> {
  if (authBackendInstance === undefined) {
    const mod = await import(`./${environment.AUTH_BACKEND}`);
    authBackendInstance = new mod.default() as IAuthBackend;
  }
  return authBackendInstance;
}
